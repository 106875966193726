<template>
  <div
    class="d-flex align-items-center"
    :class="{
      'flex-row ml-2 p-1 text-break': rows,
      'flex-column p-1 p-md-2 m-1 m-md-2 cursor-pointer': !rows,
    }"
    @click="btnShow = !btnShow"
    :title="file.file_name"
  >
    <div>
      <img
        :src="'/formats/' + file.file_extension + '.svg'"
        :class="{ 'icon-sm mr-2': rows, 'icon-md': !rows, 'ml-3' : file.file_version > 1, '' : file.file_version <= 1 }"
      />
    </div>
    <div>
      <span
        class="mt-2"
        :class="{
          '': rows,
          'text-break text-file-grid text-center': !rows,
        }"
      >
        <span >
          <small>{{ file.file_name + "." + file.file_extension }}</small>
          <small class="fst-italic"> - v{{ file.file_version || 1 }}  Le {{ $moment(file.system_insert_time).format("DD/MM/YYYY") }}</small>
        </span>
      </span>
    </div>
    <div
      class="text-muted d-flex align-items-center"
      :class="{ 'mt-2': !rows, 'ml-auto': rows }"
      v-if="btnShow || rows"
    > 
      <ButtonCircle v-if="file.file_parent == '0'"
        :loading="file.loadingDownload"
        size="18"
        name="plus"
        colorClass="btn-success"
        @click="$emit('newVersion')"
        v-tooltip="'Nouvelle version'"
      ></ButtonCircle>
      <ButtonCircle
        :loading="file.loadingDownload"
        size="18"
        name="download"
        colorClass="btn-success"
        @click="$emit('download')"
        v-tooltip="fileConvertSize(file.file_size)"
      ></ButtonCircle>
      <ButtonCircle
        :loading="file.loadingDelete"
        size="18"
        name="x"
        colorClass="btn-danger"
        @click="deleteFile"
        v-tooltip="'Supprimer'"
      ></ButtonCircle>
    </div>
  </div>
</template>
<script>
import ButtonCircle from "@/components/bases/ButtonCircle.vue";

export default {
  components: { ButtonCircle },
  props: {
    file: {
      Type: Object,
      Requierd: true,
      Default: {},
    },
    rows: {
      Type: Boolean,
      Requierd: true,
      Default: false,
    },
  },
  data() {
    return {
      btnShow: false,
    };
  },
  methods: {
    fileConvertSize: function (aSize) {
      aSize = Math.abs(parseInt(aSize, 10));
      var def = [
        [1, "octets"],
        [1024, "ko"],
        [1024 * 1024, "Mo"],
        [1024 * 1024 * 1024, "Go"],
        [1024 * 1024 * 1024 * 1024, "To"],
      ];
      for (var i = 0; i < def.length; i++) {
        if (aSize < def[i][0])
          return (aSize / def[i - 1][0]).toFixed(2) + " " + def[i - 1][1];
      }
    },
    deleteFile() {
      if (!this.file.loadingDelete) {
        this.$emit("delete");
      }
    },
  },
};
</script>
<style lang="css">
.icon-sm {
  width: 25px;
}
.icon-md {
  width: 40px;
}

.text-file-grid {
  width: 160px;
}

small.fst-italic {
  font-style:italic;
}

@media (max-width: 575.98px) {
  .doc-width {
    width: 100px;
  }
}

@media (max-width: 767.98px) {
  .doc-width {
    width: 100px;
  }
}

@media (max-width: 991.98px) {
  .doc-width {
    width: 100px;
  }
}

@media (max-width: 1199.98px) {
  .doc-width {
    width: 300px;
  }
}
</style>
