<template>
  <div class="">
    <div class="d-flex align-items-center border-toolbar">
      <h6 class="m-0">{{ title }}</h6>
      <div class="ml-auto d-flex flex-row pb-1 pt-1 pr-2">
        <label class="m-0" :for="id">
          <button-circle name="file-plus" size="16" v-tooltip="'Ajouter des fichiers'"></button-circle>
        </label>
      </div>
    </div>
    <div class="d-flex align-items-center" v-if="loading">
      <loader class="m-5"></loader>
      <h2 class="m-0">Chargement...</h2>
    </div>
    <div class="d-flex flex-wrap" :class="{ 'flex-column border-bottom-files': rows }">
      <div v-show="!loading" v-for="file in files" :key="file.id">
        <uploader-item
          :file="file"
          :rows="rows"
          @newVersion="newVersionDocument(file, `${id}`)"
          @download="downloadDocument(file)"
          @delete="deleteDocument(file)"
        ></uploader-item>
      </div>
      <div v-show="!loading" v-for="file in filesToUpload" :key="file.id">
        <uploader-item-new :file="file" :rows="rows" :errors="errors"></uploader-item-new>
      </div>
      <div v-if="errors.length > 0" class="mt-2">
        <div v-for="err in errors" :key="err.id" class="text-danger">
          <small>{{ err }}</small>
        </div>
      </div>
    </div>
    <div
      class="p-3 text-center rounded mt-3 mb-3"
      :class="{ 'bg-success text-white': over, 'text-muted': !over }"
      v-background-2
      @drop.prevent="addFileToUpload"
      @dragover.prevent
      @dragenter="over = true"
      @dragleave="over = false"
    >
      Déposer vos fichiers ici
    </div>
    <!-- DOC UPLOADER -->
    <input :id="id" type="file" @change="addFileToUpload" class="d-none" multiple :accept="file_accepted" />
    <input type="hidden" :id="`version${id}`" />
  </div>
</template>
<script>
import UploaderItem from "@/components/bases/UploaderItem.vue";
import UploaderItemNew from "@/components/bases/UploaderItemNew.vue";
import Loader from "@/components/bases/Loader.vue";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";

export default {
  name: "Uploader",
  components: { UploaderItem, Loader, UploaderItemNew, ButtonCircle },
  data: function () {
    return {
      files: [],
      filesToUpload: [],
      loading: false,
      progress: 0,
      rows: true,
      errors: [],
      over: false,
      file_parent: 0,
    };
  },
  props: {
    id: {
      type: String,
      default: "document",
    },
    url: {
      type: String,
      default: "",
    },
    file_system: {
      type: String,
      default: "",
    },
    file_field: {
      type: String,
      default: "",
    },
    file_key: {
      type: [String, Number],
      default: "",
    },
    file_name: {
      type: String,
      default: "auto",
    },
    file_accepted: {
      type: String,
      default: ".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .xlsm",
    },
    title: {
      type: String,
      default: "Liste des fichiers",
    },
  },
  computed: {},
  mounted() {
    this.getDocuments();
  },
  methods: {
    test(e) {
      console.log("enter", e);
    },
    test2(e) {
      console.log("leave", e);
    },

    getDocuments: function () {
      this.loading = true;
      this.$http
        .get("/documents", {
          params: {
            file_system: this.file_system,
            file_field: this.file_field,
            file_key: this.file_key,
          },
        })
        .then((response) => {
          this.files = response.data;
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },

    downloadDocument: function (file) {
      let file_name = file.file_name + "." + file.file_extension;
      this.$http.get("/documents/" + file.id, { responseType: "blob" }).then((response) => {
        console.log(file);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file_name);
        document.body.appendChild(link);
        link.click();
      });
      //.finally(() => {});
    },

    newVersionDocument: function (file, id) {
     this.file_parent = file.id;
     document.getElementById("version"+id).value = file.id;
     document.getElementById(id).click();
    },

    deleteDocument: function (file) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      file.loadingDelete = true;
      this.$http
        .delete("/documents/" + file.id)
        .then(() => {
          const i = this.files.indexOf(file);
          this.files.splice(i, 1);
        })
        .catch((error) => console.log(error));
      //.finally(() => (file.loadingDelete = false));
    },

    addFileToUpload: function (e) {
      let _files = e.target.files || e.dataTransfer.files;
      for (let i = 0; i < _files.length; i++) {
        this.filesToUpload.push({
          file: _files[i],
          name: _files[i].name,
          size: _files[i].size,
          type: _files[i].type,
          progress: 0,
          state: "en attente",
          error: "",
        });
      }

      this.upload();
      this.over = false;
    },
    upload: async function () {
      for (let i = 0; i < this.filesToUpload.length; i++) {
        let formData = new FormData();
        this.filesToUpload[i].state = "en cours de chargement";
        formData.append("file", this.filesToUpload[i].file);
        formData.append("file_system", this.file_system);
        formData.append("file_field", this.file_field);
        formData.append("file_key", this.file_key);
        formData.append("file_parent", this.file_parent);
        await this.$http
          .post(this.url, formData, {
            onUploadProgress: (progressEvent) => {
              if (progressEvent.lengthComputable) {
                this.filesToUpload[i].progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
              }
            },
          })
          .then(() => {
            this.filesToUpload[i].state = "Terminé";
            this.filesToUpload[i].progress = 0;
          })
          .catch((error) => this.errors.push(error.response.data.error.file[0]))
          .finally(() => {});
      }
      this.getDocuments();
      this.filesToUpload = [];
      this.file_parent = 0;
    },
  },
};
</script>
<style>
.border-toolbar {
  border-bottom: 1px solid rgb(1, 1, 1, 0.05);
}

.border-bottom-files > div:last-child {
  border-bottom: none;
}

.border-bottom-files > div {
  border-bottom: 1px solid rgb(1, 1, 1, 0.05);
}
</style>
